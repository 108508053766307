

.react-pdf__Page {
    position: relative;
    width: 100%;
    
    border: 1px solid black;
  }

.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* This will push the <p> to the left and the buttons to the right */
    background-color: #f9f9f9; /* Light gray background */
    padding: 10px 20px; /* Padding to space things out */
    border: 1px solid #ddd;
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 10px; /* Space between the toolbar and the content below it */
  }

.toolbar p {
    display: flex;
    margin: 0; /* Remove the default margin of the paragraph */
    gap: 10px;
  }
  
  .button-group {
    display: flex;
    gap: 10px; /* Provides space between the buttons */
  }
  
  .toolbar button {
    margin-left: 10px; /* Space between the buttons */
  }
  
  .navigation-row {
    display: flex;
    align-items: center;
    gap: 20px; /* space between dropdown and links */
    
  }

  .expandableRow td {
    width: 13%; /*override the default from 33.3%*/
  }


  tr.case-row-highlight {
    background-color: #e0f7ff;  /* Or any other color you'd like */
}

.pdf-page-container {
  position: relative;
  width: 100%;
  display: none; /* Hide by default */
  border: 1px solid black;
}

.pdf-page-container.active-page {
  display: block; /* Only active page should be visible */
}


.custom-modal .modal-body {
  max-height: calc(100vh - 210px); /* Adjust the value to fit your needs */
  overflow-y: auto;
}

